
import Vue from "vue";
//@ts-ignore
import { getEnabledActions, getOrderedActions } from "@/models/CardAction";
//@ts-ignore
import { CardActionEntity } from "@/models/CardAction";
//@ts-ignore
import { CardActionColor, CardActionType } from "@/interfaces/action";

export default Vue.extend({
	name: "CardActions",
	props: {
		reference: {
			type: String,
			default: "",
		},
		showBtns: {
			type: Array,
			default: function () {
				return [];
			},
		},
		disabled: {
			type: Object,
			default: function () {
				return {};
			},
		},
		texts: {
			type: Object,
			default: function () {
				return {};
			},
		},
		variant: {
			type: String,
			default: CardActionColor.SECONDARY,
		},
	},
	components: {},
	data: () => ({
		cardActions: [] as CardActionEntity[],
	}),
	async created() {
		this.$nextTick(async () => {
			await this.updateComponenteData();
		});
	},
	async mounted() {},
	computed: {
		prepareEnabledActions(): CardActionEntity[] {
			return getEnabledActions(this.showBtns);
		},
	},
	methods: {
		handleAction(type: any) {
			this.$emit("action", { type: type });
		},
		async updateComponenteData() {
			this.cardActions = await this.getOrderedActions();
			await this.detectDisabled();
			await this.detectCustomText();
			this.setButtonColor();
		},
		async getOrderedActions(): Promise<CardActionEntity[]> {
			const actions: CardActionEntity[] = this.prepareEnabledActions;
			const orderedActions = getOrderedActions(actions);
			return orderedActions;
		},
		async detectDisabled() {
			if (!this.disabled) return;
			for (const [key, value] of Object.entries(this.disabled)) {
				this.cardActions
					.find((a: { type: string }) => a.type === key)
					?.setDisabled(value as Boolean);
			}
		},
		async detectCustomText() {
			if (!this.texts) return;
			for (const [key, value] of Object.entries(this.texts)) {
				this.cardActions
					.find((a: { type: string }) => a.type === key)
					?.setText(value as CardActionType);
			}
		},
		setButtonColor() {
			this.cardActions.forEach((bttn: CardActionEntity) => {
				bttn.color = this.variant;
			});
		},
	},
	watch: {
		disabled: {
			async handler() {
				await this.detectDisabled();
				await this.detectCustomText();
			},
			deep: true,
			immediate: true,
		},
		showBtns: {
			async handler(val, oldVal) {
				await this.updateComponenteData();
			},
			deep: true,
			immediate: true,
		},
	},
});
