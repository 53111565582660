import Vue from "vue";
import { TypeLoading } from "@/interfaces/loading";
import { mapActions, mapGetters } from "vuex";
import { Paginated, SortingOption } from "@/interfaces/paginated";
import { getDefaultSortingOption } from "@/utils/filter-global";
import paramsService from "@/services/params-service";
import {
	prepareSelectedData,
	prepareTableContent,
	prepareTableData,
	preparedColumnDefsGrid,
} from "@/utils/CustomizeViewData";
import { isEmpty, isNull, isUndefined } from "lodash";
import GridTable from "@/components/Header/Tables/GridTable.vue";
import ActionsTable from "@/components/Header/Tables/ActionsTable.vue";
import Buttons from "@/views/Admin/Commons/Buttons.vue";
import omit from "lodash/omit";
import FilterGridAG from "@/components/Header/Tables/FilterGridAG.vue";
import { getPermisionPlanning } from "@/utils/permissionResolve";
import NotPermission from "@/views/Errors/not_permission.vue";

export default Vue.extend({
	name: "PrivatePoisTable",
	props: {},
	components: { GridTable, ActionsTable, Buttons, FilterGridAG, NotPermission },
	data: () => ({
		paginated: { page: 1, limit: 25 } as Paginated,
		filters: {},
		options: getDefaultSortingOption("id"),
		table: {
			headers: [],
			items: [],
		},
		fields: {},
		columnRestored: false,
		context: null,
		selectionRows: "single",
		interval: null,
	}),
	created() {
		this.$nextTick(async () => {});
	},
	mounted() {
		this.$nextTick(async () => {
			await this.saveFilters();
			await this.verifyFilters();
			await this.getPaginated();
			this.context = { componentParent: this };

			if(this.canList){
				this.interval = setInterval(async () => {
					await this.paginatedPrivatePois(await this.getConfig);
					await this.updateItemsTable();
				}, 10000);
			}
		});
	},
	beforeDestroy() {
		clearInterval(this.interval);
	},
	computed: {
		...mapGetters("customize_view", [
			"getFieldByView",
			"getSelectedByView",
		]),
		...mapGetters("privatePois", [
			"getResultPaginatedPrivatePOIs",
			"isCreated",
		]),
		...mapGetters("internationalization", ["getLanguage"]),
		...mapGetters("customizer_filter", ["getFiltersEntity"]),
		...mapGetters("profile", ["getAbility"]),
		config() {
			return {
				paginated: this.paginated,
				filters: this.filters,
				options: this.options,
				fields: this.fields,
			};
		},
		async getConfig() {
			return await paramsService.getParams(
				this.paginated,
				this.filters,
				this.options,
				this.fields
			);
		},
		getResultPaginate() {
			return this.getResultPaginatedPrivatePOIs;
		},
		getData(): any[] {
			const result: any = this.getResultPaginate;
			return this.hasData(result) ? result.data : [];
		},
		getConfigColumnDef() {
			return {
				context: this.context,
				entityName: "privatepois",
				redirect: "PrivatePoisEdit",
				edit: true,
				checkDisableEdit: true,
				checkDisableEditPrivate: true,
				minWidthActions: 200,
				maxWidthActions: 200,
				permission: this.getPermission,
			};
		},
		getColumnsDef() {
			return preparedColumnDefsGrid(
				this.table.headers,
				this.getConfigColumnDef
			);
		},

		getFiltrosAplicados() {
			const newFilters =
				this.getFiltersEntity("PrivatePoisCreate").filters;
			return Object.keys(newFilters).length > 0 ? newFilters : {};
		},

		getOptionsAplicados() {
			return this.getFiltersEntity("PrivatePoisCreate").options;
		},

		gerRowsData(): any[] {
			return this.table.items || [];
		},

		getSize() {
			return this.$t("show.privatepois", {
				total: this.getData.length,
			});
		},

		getPermission(){
			return getPermisionPlanning();
		},

		canList(){
			return this.getAbility.can(this.getPermission.actions.index, this.getPermission.subject);
		}
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("privatePois", ["paginatedPrivatePois"]),
		/** Begin Customize View **/

		...mapActions("proccess", ["setNotification"]),

		...mapActions("customize_view", [
			"setFields",
			"saveSelected",
			"saveColumns",
			"getTableDataSelected",
		]),

		...mapActions("customizer_filter", [
			"saveFiltersEntity",
			"resetFilterEntity",
		]),

		async updateItemsTable() {
			const selected: any = await this.getSelectedByView(
				this.$route?.name
			);

			this.fields = await prepareSelectedData(selected);

			this.table.items = await prepareTableContent(
				this.fields,
				this.getData
			);
			this.$forceUpdate();
		},

		async getTableData(
			event: { view: string; isReset: Boolean } = {
				view: "",
				isReset: true,
			}
		) {
			const { selected, fields } = await this.getTableDataSelected({
				route: this.$route?.name,
				entity: this.getData,
			});

			this.fields = fields;

			const headers: Array<any> = selected.columns;
			const columns: Array<any> = await this.getColumns();

			this.table = await prepareTableData({
				columns: columns,
				fields: this.fields,
				entity: "privatepois",
				entities: this.getData,
				isReset: event?.isReset,
			});

			if (isEmpty(headers) || event?.isReset) {
				await this.saveColumns({ event, columns: this.table.headers });
				//this.columnRestored = event?.isReset
			}

			this.$forceUpdate();
		},

		async getColumns() {
			const selected: any = await this.getSelectedByView(
				this.$route?.name
			);
			const columns: Array<any> = selected.columns;
			return columns ?? [];
		},

		async verifyFilters() {
			const newFilters = this.getFiltrosAplicados;
			if (Object.keys(newFilters).length < 1) return;
			this.filters = newFilters;
			this.options = this.getOptionsAplicados;
		},

		async handleSave(event: any) {
			await this.saveSelected(event);
			await this.saveColumns({ event, columns: this.table.headers });
			await this.getTableData(event);
		},

		/** End Customize View **/

		hasData(result: { data: any }): Boolean {
			return (
				!isUndefined(result) &&
				!isNull(result) &&
				!isUndefined(result.data) &&
				!isNull(result.data)
			);
		},

		async getPaginated(uTable: boolean = true) {
			if(this.canList){
				await this.setLoadingData(TypeLoading.loading);
				await this.paginatedPrivatePois(await this.getConfig);
				if (uTable) {
					await this.getTableData({ view: this.$route?.name });
				} else {
					await this.updateItemsTable();
				}
				await this.setLoadingData();
			}
		},

		updatePaginate(data: any) {
			this.paginated.page = data;
		},

		async setFilter(params: { key: string | number; value: any }) {
			this.filters = {};
			this.filters[params.key] = !isUndefined(params.value)
				? params.value
				: "";
			await this.saveFilters();
		},

		async handleUpdateColumns(event: any) {
			await this.saveSelected(event);
		},

		async handleUpdateSizeColumns(params: any) {
			params.forEach((element) => {
				let col = this.table.headers.filter(
					(c) => c.value == element.col
				);
				col[0].reactive_width = element.actualWidth;
				col[0].width = String(element.actualWidth) + "px";
			});
			let event = {
				view: params.entity,
			};
			await this.saveColumns({ event, columns: this.table.headers });
		},

		async selectedOption(params: { options: SortingOption; filter: any }) {
			await this.setFilter({
				key: params.options.sort,
				value: params.filter,
			});
			this.updatePaginate(1);
			await this.updateParams(
				{
					filters: this.filters,
					options: params.options,
				},
				false
			);
		},

		async selectedLimit(limit: number) {
			this.paginated.limit = limit;
			this.updatePaginate(1);
			await this.getPaginated(false);
		},
		async removeKeyFilter(key: string) {
			const newFilters: any = omit(this.filters, [key]);
			await this.updateParams(
				{
					filters: newFilters,
					options: this.options,
				},
				false
			);
			await this.saveFilters();
		},

		async updateParams(
			params: {
				filters: any;
				options: SortingOption;
			},
			uTable: boolean = true
		) {
			this.filters = params.filters;
			this.options = params.options;
			this.updatePaginate(1);
			await this.getPaginated(uTable);
		},

		async removeOption() {
			this.filters = {};
			this.options = getDefaultSortingOption("id");
			await this.resetFilter();
			this.updatePaginate(1);
			await this.getPaginated(false);
		},
		async saveFilters() {
			this.saveFiltersEntity({
				view: this.$route?.name,
				filters: { filters: this.filters, options: this.options },
			});
		},
		async resetFilter() {
			this.resetFilterEntity({
				view: this.$route?.name,
				filters: { filters: {}, options: {} },
			});
		},
		async handleResetColumns() {
			await this.getTableData({ view: this.$route?.name, isReset: true });
		},
	},
	watch: {
		"paginated.page"(val, old) {
			if (val !== old) {
				this.getPaginated();
			}
		},
		async getLanguage(lang) {
			await this.handleResetColumns();
			this.$forceUpdate();
		},
		isCreated(val: Boolean, old) {
			if (val) {
				this.getPaginated();
			}
		},
	},
});
